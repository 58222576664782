import {
  Button,
  Cascader,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Space,
  Upload,
  UploadFile,
  UploadProps
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import global from "../../global";
import {useDebounce} from "usehooks-ts";
import dayjs from "dayjs";
import idCard from "../../util/idCard";
import {RcFile, UploadChangeParam} from "antd/es/upload";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

const {Option} = Select

const ExamApplyModify = (props: { form: any, messageApi: any }) => {
  const country = useSelector((state: any) => state.country)
  const city = useSelector((state: any) => state.city)
  const nation = useSelector((state: any) => state.nation)
  const examApply = useSelector((state: any) => state.examApply)
  const file = useSelector((state: any) => state.file)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [pinyinList, setPinyinList] = useState<any[]>([])
  const [showPinyinInput, setShowPinyinInput] = useState(false)

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      let data = info.file.response.data;
      let imageUrl = global.getFileOpenUrl(data);
      setImageUrl(imageUrl);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const UploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>上传</div>
    </div>
  );

  const [idCardName, setIdCardName] = React.useState<string>("")
  const idCardNameDebounce = useDebounce<string>(idCardName, 500)

  useEffect(() => {
    dispatch.pinyin.list({str: idCardNameDebounce}).then((res: any) => {
      if (res.code === 0) {
        setPinyinList(res.data)
      }
    })
  }, [idCardNameDebounce, dispatch.pinyin]);

  const onIdCardNumberChange = (e: any) => {
    let {value} = e.target
    if (value.length === 18) {
      let birthday = dayjs(idCard.getBirthday(value));
      let sex = idCard.getSex(value);
      props.form.setFieldsValue({birthday, sex})
    }
  }

  const onModifyClose = () => {
    dispatch.examApply.setData({openModify: false})
  }

  const onModifySave = () => {
    props.form.submit()
  }

  const onModifyFinish = (values: any) => {
    values.birthday = dayjs(values.birthday).format("YYYY-MM-DD")
    dispatch.examApply.modify(values).then((res: any) => {
      if (res.code === 0) {
        props.messageApi.success("修改成功")
        dispatch.examApply.page()
        dispatch.examApply.setData({openModify: false})
        props.form.resetFields()
      } else {
        props.messageApi.error(res.msg)
      }
    });
  }

  const UploadImage = () => {
    return examApply.modifyPhoto.fileKey === "" ?
      UploadButton : <img src={global.getFileOpenUrl(examApply.modifyPhoto)} alt="avatar" style={{width: '100%'}}/>
  }

  return (
    <Drawer
      title="修改报名"
      width={500}
      onClose={onModifyClose}
      open={examApply.openModify}
      bodyStyle={{paddingBottom: 80}}
      extra={
        <Space>
          <Button onClick={onModifyClose}>取消</Button>
          <Button onClick={onModifySave} type="primary">
            保存
          </Button>
        </Space>
      }
    >
      <Form form={props.form} onFinish={onModifyFinish} layout="vertical">
        <Form.Item name="id" label="编号" hidden>
          <Input/>
        </Form.Item>

        <Form.Item name="examPlanInfo" label="考试计划">
          <Input disabled={true}/>
        </Form.Item>

        <Form.Item name="level" label="报考级别" rules={[{required: true, message: "选择报考级别"}]}>
          <Select placeholder="选择报考级别">
            {
              examApply.modifyGrades.map((item: any, index: number) => {
                return <Option key={index} value={item.level}>{item.name}</Option>
              })
            }
          </Select>
        </Form.Item>

        <Form.Item name="idCardName" label="考生姓名" rules={[{required: true, message: "考生姓名"}]}>
          <Input placeholder="考生姓名" onChange={(e) => setIdCardName(e.target.value)}/>
        </Form.Item>

        <Form.Item name="namePinyin" label="姓名拼音" rules={[{required: true, message: "姓名拼音"}]}>
          {
            showPinyinInput ?
              <Input placeholder="姓名拼音"/>
              :
              <Select placeholder="选择拼音" onChange={(value: string) => {
                if (value === '') {
                  setShowPinyinInput(true)
                }
              }}>
                {
                  pinyinList.map((name: string, index: number) => {
                    return <Option key={index} value={name}>{name}</Option>
                  })
                }
                <Option value=''>手动输入</Option>
              </Select>
          }
        </Form.Item>

        <Form.Item name="idCardType" label="证件类型" rules={[{required: true, message: "选择证件类型"}]}>
          <Select placeholder="选择证件类型">
            <Option value="身份证">身份证</Option>
            <Option value="护照">护照</Option>
            <Option value="港澳通行证">港澳通行证</Option>
          </Select>
        </Form.Item>

        <Form.Item name="idCardNumber" label="证件号码" rules={[{required: true, message: "输入证件号码"}]}>
          <Input placeholder="输入证件号码" onChange={onIdCardNumberChange}/>
        </Form.Item>

        <Form.Item
          name="birthday"
          label="出生日期"
          rules={[{required: true, message: "选择出生日期"}]}>
          <DatePicker placeholder="选择出生日期" format="YYYY-MM-DD"/>
        </Form.Item>

        <Form.Item name="sex" label="性别" rules={[{required: true, message: "选择性别"}]}>
          <Select placeholder="选择性别">
            <Option value="男">男</Option>
            <Option value="女">女</Option>
          </Select>
        </Form.Item>

        <Form.Item name="country" label="国籍" rules={[{required: true, message: "选择国籍"}]}>
          <Select placeholder="选择国籍">
            {
              country.list.map((item: any, index: number) => {
                return <Option key={index} value={item.name}>{item.name}</Option>
              })
            }
          </Select>
        </Form.Item>

        <Form.Item name="nation" label="民族" rules={[{required: true, message: "选择民族"}]}>
          <Select placeholder="选择民族">
            {
              nation.list.map((item: any, index: number) => {
                return <Option key={index} value={item.name}>{item.name}</Option>
              })
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="area"
          label="地区"
          rules={[{required: true, message: "选择地区"}]}
          getValueFromEvent={(e) => {
            return e.join('/')
          }}>
          <Cascader options={city.list} placeholder="选择地区"/>
        </Form.Item>

        <Form.Item name="address" label="联系地址">
          <Input placeholder="可不填" value={""}/>
        </Form.Item>

        <Form.Item
          name="photo"
          label="上传照片"
          valuePropName="file"
          getValueFromEvent={(e) => {
            if (e.file.status !== "done") return
            return e.file.response.data.fileKey
          }}
          rules={[{required: true, message: "选择文件"}]}
        >
          <Upload
            name="file"
            data={{folder: 'photo', fileType: 'PRIVATE'}}//为上传文件增加额外的body参数
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            headers={global.getUploadHeaders()} //指定上传头，这个有授权sessionKey
            action={file.upload.url + "/add"} //上传地址，不预先获取fileKey，每次都生成新的fileKey
            beforeUpload={beforeUpload}   //上传前的钩子函数，检查文件大小、类型等
            onChange={handleChange}
          >
            {
              imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : <UploadImage/>
            }
          </Upload>
        </Form.Item>

        <Form.Item name="orgName" label="机构名称">
          <Input placeholder="请输入机构名称"/>
        </Form.Item>

        <Form.Item name="teacher" label="指导老师" rules={[{required: true, message: "输入指导老师"}]}>
          <Input placeholder="输入指导老师"/>
        </Form.Item>

        <Form.Item name="teacherMobile" label="老师电话" rules={[{required: true, message: "输入老师电话"}]}>
          <Input placeholder="输入老师电话"/>
        </Form.Item>

        <Form.Item name="parents" label="家长姓名" rules={[{required: true, message: "输入家长姓名"}]}>
          <Input placeholder="输入家长姓名"/>
        </Form.Item>

        <Form.Item name="mobile" label="家长电话" rules={[{required: true, message: "输入家长电话"}]}>
          <Input placeholder="输入家长电话"/>
        </Form.Item>

        <Form.Item
          name="memo"
          label="备注"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="输入应用备注"/>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default ExamApplyModify;