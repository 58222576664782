import React from "react"
import "./Company.less"
import CompanyTable from "./CompanyTable"

/**
 * company
 * @constructor
 */
const Company: React.FC = () => {

  return (
    <div className="Company">
      <CompanyTable/>
    </div>
  )
}

export default Company
