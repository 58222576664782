import {
  Button,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Space,
  Upload,
  UploadFile,
  UploadProps
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import global from "../../global";
import dayjs from "dayjs";
import {RcFile, UploadChangeParam} from "antd/es/upload";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

const {Option} = Select

const ValuationApplyModify = (props: { form: any, messageApi: any }) => {
  const valuationApply = useSelector((state: any) => state.valuationApply)
  const file = useSelector((state: any) => state.file)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      let data = info.file.response.data;
      let imageUrl = global.getFileOpenUrl(data);
      setImageUrl(imageUrl);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const UploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>上传</div>
    </div>
  );

  const onModifyClose = () => {
    dispatch.valuationApply.setData({openModify: false})
  }

  const onModifySave = () => {
    props.form.submit()
  }

  const onModifyFinish = (values: any) => {
    values.birthday = dayjs(values.birthday).format("YYYY-MM-DD")
    dispatch.valuationApply.modify(values).then((res: any) => {
      if (res.code === 0) {
        props.messageApi.success("修改成功")
        dispatch.valuationApply.page()
        dispatch.valuationApply.setData({openModify: false})
        props.form.resetFields()
      } else {
        props.messageApi.error(res.msg)
      }
    });
  }

  const UploadImage = () => {
    return valuationApply.modifyPhoto.fileKey === "" ?
      UploadButton : <img src={global.getFileOpenUrl(valuationApply.modifyPhoto)} alt="avatar" style={{width: '100%'}}/>
  }

  return (
    <Drawer
      title="修改报名"
      width={500}
      onClose={onModifyClose}
      open={valuationApply.openModify}
      bodyStyle={{paddingBottom: 80}}
      extra={
        <Space>
          <Button onClick={onModifyClose}>取消</Button>
          <Button onClick={onModifySave} type="primary">
            保存
          </Button>
        </Space>
      }
    >
      <Form form={props.form} onFinish={onModifyFinish} layout="vertical">
        <Form.Item name="id" label="编号" hidden>
          <Input/>
        </Form.Item>

        <Form.Item name="examPlanInfo" label="考试计划">
          <Input disabled={true}/>
        </Form.Item>

        <Form.Item name="level" label="级别" rules={[{required: true, message: "选择级别"}]}>
          <Select placeholder="选择级别">
            {
              valuationApply.modifyGrades.map((item: any, index: number) => {
                return <Option key={index} value={item.level}>{item.name}</Option>
              })
            }
          </Select>
        </Form.Item>

        <Form.Item name="idCardName" label="姓名" rules={[{required: true, message: "姓名"}]}>
          <Input placeholder="姓名"/>
        </Form.Item>

        <Form.Item name="idCardNumber" label="身份证号码" rules={[{required: true, message: "输入身份证号码"}]}>
          <Input placeholder="输入身份证号码" />
        </Form.Item>

        <Form.Item
          name="photo"
          label="上传照片"
          valuePropName="file"
          getValueFromEvent={(e) => {
            if (e.file.status !== "done") return
            return e.file.response.data.fileKey
          }}
          rules={[{required: true, message: "选择文件"}]}
        >
          <Upload
            name="file"
            data={{folder: 'photo', fileType: 'PRIVATE'}}//为上传文件增加额外的body参数
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            headers={global.getUploadHeaders()} //指定上传头，这个有授权sessionKey
            action={file.upload.url + "/add"} //上传地址，不预先获取fileKey，每次都生成新的fileKey
            beforeUpload={beforeUpload}   //上传前的钩子函数，检查文件大小、类型等
            onChange={handleChange}
          >
            {
              imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : <UploadImage/>
            }
          </Upload>
        </Form.Item>

        <Form.Item name="orgName" label="所在机构">
          <Input placeholder="请输入所在机构"/>
        </Form.Item>

        <Form.Item name="mobile" label="联系电话" rules={[{required: true, message: "输入联系电话"}]}>
          <Input placeholder="输入联系电话"/>
        </Form.Item>

        <Form.Item
          name="memo"
          label="备注"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="输入应用备注"/>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default ValuationApplyModify;