import React from 'react';
import './Error.less';

const Error: React.FC = () => {

  return (
    <div className='Error'>
      Error
    </div>
  )
}
export default Error;