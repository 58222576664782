import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const company = createModel<RootModel>()({
  state: {
    info: {},
    request: {
      keyword: "",
      pageNum: 1,
      pageSize: 20,
      companyType: '',
    },
    data: {
      total: 0,
      pageNum: 1,
      pageSize: 20,
      list: [],
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 分页显示
     *
     * @param payload
     * @param rootState
     */
    async page(payload, rootState): Promise<any> {
      let res = await request.post("/partner/v1/company/page", rootState.company.request)
      if (res.code === 0) {
        res.data.list.forEach((item: any) => {
          item.key = item.id
        })
        dispatch.company.setData({data: res.data})
      }
      return res
    },

    /**
     * 查询下级考试单位
     *
     * @param payload
     * @param rootState
     */
    async auditCompanies(payload, rootState): Promise<any> {
      let res = await request.get("/partner/v1/company/auditCompanies")
      if (res.code === 0) {
        res.data.forEach((item: any) => {
          item.key = item.id
        })
      }
      return res
    },

    /**
     * 查询当前单位的运营专业
     *
     * @param payload
     * @param rootState
     */
    async speciality(payload, rootState): Promise<any> {
      let res = await request.get("/partner/v1/company/speciality/" + payload.auditType)
      if (res.code === 0) {
        res.data.forEach((item: any) => {
          item.key = item.id
        })
      }
      return res
    }
  }),
})