import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const country = createModel<RootModel>()({
  state: {
    list: [],
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 读取中国的所有国家
     * @param payload
     * @param rootState
     */
    async list(payload, rootState) {
      let res = await request.get("/pub/v1/country/list")
      if (res.code > 0) {
        console.log(res.msg)
      } else {
        dispatch.country.setData({list:res.data})
      }
    }
  }),
})