import {Button, DatePicker, Drawer, Form, Input, message, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";

const ExamPlanAdd = () => {
  const [messageApi, contextMessageHolder] = message.useMessage()
  const examPlan = useSelector((state: any) => state.examPlan)
  const [auditCompanies, setAuditCompanies] = useState<any>([])
  const dispatch = useDispatch()
  const [addForm] = Form.useForm()
  const onAddClose = () => {
    dispatch.examPlan.setData({openAdd: false})
  }
  const onAddSave = () => {
    addForm.submit()
  }
  const onNewFinish = (values: any) => {
    values.auditDate = dayjs(values.auditDate).format("YYYY-MM-DD")
    values.expireDate = dayjs(values.expireDate).format("YYYY-MM-DD")
    dispatch.examPlan.add(values).then((res: any) => {
      if (res.code !== 0) {
        messageApi.error(res.msg)
        return;
      }
      messageApi.success("创建成功")
      addForm.resetFields()
      dispatch.examPlan.setData({openAdd: false})
      dispatch.examPlan.page()
    });
  }

  // 专业列表
  const [specialityList, setSpecialityList] = useState([]);

  useEffect(() => {
    if (examPlan.openAdd) {
      dispatch.company.speciality({auditType: 'EXAM'}).then((res: any) => {
        if (res.code > 0) {
          messageApi.error(res.msg)
          return;
        }
        setSpecialityList(res.data);
      });
      dispatch.company.auditCompanies().then((res: any) => {
        setAuditCompanies(res.data)
      })
    }
  }, [examPlan.openAdd, dispatch.speciality, dispatch.company, messageApi]);

  return (<>
    {contextMessageHolder}
    <Drawer
      title="新增测评计划"
      width={500}
      onClose={onAddClose}
      open={examPlan.openAdd}
      bodyStyle={{paddingBottom: 80}}
      extra={
        <Space>
          <Button onClick={onAddClose}>取消</Button>
          <Button onClick={onAddSave} type="primary">
            保存
          </Button>
        </Space>
      }
    >
      <Form form={addForm} onFinish={onNewFinish} layout="vertical">

        {/*选择专业*/}
        <Form.Item name="specialityId" label="专业" rules={[{required: true, message: "选择专业"}]}>
          <Select placeholder="选择专业">
            {
              specialityList.map((item: any, index: number) => {
                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
              })
            }
          </Select>
        </Form.Item>
        {/*选择测评日期*/}
        <Form.Item name="auditDate" label="测评日期" rules={[{required: true, message: "选择测评日期"}]}>
          <DatePicker placeholder="选择测评日期" format="YYYY-MM-DD"/>
        </Form.Item>
        {/*选择报名截止日期*/}
        <Form.Item name="expireDate" label="报名截止日期" rules={[{required: true, message: "选择报名截止日期"}]}>
          <DatePicker placeholder="选择报名截止日期" format="YYYY-MM-DD"/>
        </Form.Item>
        {/*输入测评地区*/}
        <Form.Item name="area" label="测评地区" rules={[{required: true, message: "选择测评地区"}]}>
          <Input placeholder="选择测评地区"/>
        </Form.Item>
        <Form.Item name="auditCompanyId" label="测评单位" rules={[{required: true, message: "选择考点"}]}>
          <Select placeholder="选择考点" onSelect={(e) => {
            let company = auditCompanies.find((obj: { id: number; }) => obj.id === e);
            addForm.setFieldValue('address', company.address);
          }}>
            {
              auditCompanies.map((item: any, index: number) => {
                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
              })
            }
          </Select>
        </Form.Item>
        {/*输入测评地址*/}
        <Form.Item name="address" label="测评地址" rules={[{required: true, message: "选择测评地址"}]}>
          <Input placeholder="选择测评地址"/>
        </Form.Item>
        {/*输入备注*/}
        <Form.Item
          name="memo"
          label="备注"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="输入备注"/>
        </Form.Item>
      </Form>
    </Drawer>
  </>);
}

export default ExamPlanAdd;