import {Models} from "@rematch/core"
import {city} from "./city"
import {session} from "./session"
import {country} from "./country";
import {dashboard} from "./dashboard";
import {home} from "./home";
import {examApply} from "./examApply";
import {qrCodeLogin} from "./qrCodeLogin";
import {file} from "./file";
import {nation} from "./nation";
import {pinyin} from "./pinyin";
import {examPlan} from "./examPlan";
import {applyScore} from "./applyScore";
import {userModule} from "./userModule";
import {companyExaminer} from "./companyExaminer";
import {speciality} from "./speciality";
import {company} from "./company";
import {menu} from "./menu";
import {valuationApply} from "./valuationApply";
import {valuationPlan} from "./valuationPlan";

export interface RootModel extends Models<RootModel> {
  applyScore: typeof applyScore
  company: typeof company
  companyExaminer: typeof companyExaminer
  country: typeof country
  nation: typeof nation
  pinyin: typeof pinyin
  city: typeof city
  dashboard: typeof dashboard
  examApply: typeof examApply
  examPlan: typeof examPlan
  file: typeof file
  home: typeof home
  menu: typeof menu
  qrCodeLogin: typeof qrCodeLogin
  session: typeof session
  speciality: typeof speciality
  userModule: typeof userModule
  valuationApply: typeof valuationApply
  valuationPlan: typeof valuationPlan
}

export const models: RootModel = {
  applyScore,
  city,
  company,
  companyExaminer,
  country,
  nation,
  pinyin,
  dashboard,
  examApply,
  examPlan,
  file,
  home,
  menu,
  session,
  qrCodeLogin,
  speciality,
  userModule,
  valuationApply,
  valuationPlan,
}
