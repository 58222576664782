import {Button, DatePicker, Drawer, Form, Input, message, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";

const ValuationPlanAdd = () => {
  const [messageApi, contextMessageHolder] = message.useMessage()
  const valuationPlan = useSelector((state: any) => state.valuationPlan)
  const session = useSelector((state: any) => state.session)
  const dispatch = useDispatch()
  const [addForm] = Form.useForm()
  const onAddClose = () => {
    dispatch.valuationPlan.setData({openAdd: false})
  }
  const onAddSave = () => {
    addForm.submit()
  }
  const onNewFinish = (values: any) => {
    values.auditDate = dayjs(values.auditDate).format("YYYY-MM-DD")
    values.expireDate = dayjs(values.expireDate).format("YYYY-MM-DD")
    dispatch.valuationPlan.add(values).then((res:any) => {
      if (res.code !== 0) {
        messageApi.error(res.msg)
        return;
      }
      messageApi.success("创建成功")
      addForm.resetFields()
      dispatch.valuationPlan.setData({openAdd: false})
      dispatch.valuationPlan.page()
    });
  }

  // 专业列表
  const [specialityList, setSpecialityList] = useState([]);

  useEffect(() => {
    if (valuationPlan.openAdd) {
      dispatch.company.speciality({auditType: 'VALUATION'}).then((res: any) => {
        if (res.code > 0) {
          messageApi.error(res.msg)
          return;
        }
        setSpecialityList(res.data);
      });
    }
  }, [dispatch.company, dispatch.speciality, messageApi, valuationPlan.openAdd]);

  return (<>
    {contextMessageHolder}
    <Drawer
      title="新增评审计划"
      width={500}
      onClose={onAddClose}
      open={valuationPlan.openAdd}
      bodyStyle={{paddingBottom: 80}}
      extra={
        <Space>
          <Button onClick={onAddClose}>取消</Button>
          <Button onClick={onAddSave} type="primary">
            保存
          </Button>
        </Space>
      }
    >
      <Form form={addForm} onFinish={onNewFinish} layout="vertical" initialValues={{'address': session.info.companyAddress}}>

        {/*选择专业*/}
        <Form.Item name="specialityId" label="专业" rules={[{required: true, message: "选择专业"}]}>
          <Select placeholder="选择专业">
            {
              specialityList.map((item: any, index: number) => {
                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
              })
            }
          </Select>
        </Form.Item>
        <Form.Item name="auditDate" label="评审日期" rules={[{required: true, message: "选择评审日期"}]}>
          <DatePicker placeholder="选择评审日期" format="YYYY-MM-DD"/>
        </Form.Item>
        <Form.Item name="expireDate" label="报名截止日期" rules={[{required: true, message: "选择报名截止日期"}]}>
          <DatePicker placeholder="选择报名截止日期" format="YYYY-MM-DD"/>
        </Form.Item>
        <Form.Item name="area" label="地区" rules={[{required: true, message: "输入地区"}]}>
          <Input placeholder="输入地区"/>
        </Form.Item>
        <Form.Item name="address" label="地址" rules={[{required: true, message: "输入地址"}]}>
          <Input placeholder="选择测评地址"/>
        </Form.Item>
        {/*输入备注*/}
        <Form.Item
          name="memo"
          label="备注"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="输入备注"/>
        </Form.Item>
      </Form>
    </Drawer>
  </>);
}

export default ValuationPlanAdd;