import {Descriptions, Drawer} from "antd";
import {useDispatch, useSelector} from "react-redux";
import global from "../../global";

const ValuationApplyInfo = () => {
  const valuationApply = useSelector((state: any) => state.valuationApply)
  const dispatch = useDispatch()

  return (
    <Drawer
      title="查看报名详情"
      width={500}
      open={valuationApply.openInfo}
      onClose={() => dispatch.valuationApply.setData({openInfo: false})}
      bodyStyle={{paddingBottom: 80}}
    >
      <Descriptions title="" column={1}>
        <Descriptions.Item label="编号">{valuationApply.info?.id}</Descriptions.Item>
        <Descriptions.Item label="专业">{valuationApply.info?.specialityName}</Descriptions.Item>
        <Descriptions.Item label="等级">{valuationApply.info?.level}</Descriptions.Item>
        <Descriptions.Item label="评审日期">{valuationApply.info?.auditDate}</Descriptions.Item>
        <Descriptions.Item label="评审地点">{valuationApply.info?.address}</Descriptions.Item>
        <Descriptions.Item label="姓名">{valuationApply.info?.idCardName}</Descriptions.Item>
        <Descriptions.Item label="电话">{valuationApply.info?.mobile}</Descriptions.Item>
        <Descriptions.Item label="证件号码">{valuationApply.info?.idCardNumber}</Descriptions.Item>
        <Descriptions.Item label="出生日期">{valuationApply.info?.birthday}</Descriptions.Item>
        <Descriptions.Item label="性别">{valuationApply.info?.sex}</Descriptions.Item>
        <Descriptions.Item label="照片">
          {
            valuationApply.info.photoFile && <img src={global.getFileOpenUrl(valuationApply.info.photoFile)} alt="" style={{width: 150}}/>
          }
        </Descriptions.Item>
        <Descriptions.Item label="所在机构">{valuationApply.info?.orgName}</Descriptions.Item>
        <Descriptions.Item label="报名状态">{valuationApply.info?.applyStatusName}</Descriptions.Item>
        <Descriptions.Item label="信息来源">{valuationApply.info?.sourceTypeName}</Descriptions.Item>
        <Descriptions.Item label="备注">{valuationApply.info?.memo}</Descriptions.Item>
        <Descriptions.Item label="报名时间">{valuationApply.info?.created}</Descriptions.Item>
      </Descriptions>
    </Drawer>
  )
}

export default ValuationApplyInfo