import React, {lazy, Suspense, useEffect} from "react"
import {HashRouter as Router, Navigate, Route, Routes} from "react-router-dom"
import Login from "./pages/login/Login"
import Test from "./pages/test/Test"
import Error from "./pages/error/Error"
import Dashboard from "./pages/dashboard/Dashboard"
import {useSelector} from "react-redux"
import {RootState} from "./store"
import ExamApply from "./pages/examApply/ExamApply";
import ApplyScore from "./pages/applyScore/ApplyScore";
import UserModule from "./pages/userModule/UserModule";
import CompanyExaminer from "./pages/companyExaminer/CompanyExaminer";
import ExamPlan from "./pages/examPlan/ExamPlan";
import Company from "./pages/company/Company";
import ValuationPlan from "./pages/valuationPlan/ValuationPlan";
import ValuationApply from "./pages/valuationApply/ValuationApply";

const Home = lazy(() => import("./pages/home/Home"))

/**
 * 应用路由
 */
const AppRouter = () => {
  let session = useSelector((state: RootState) => state.session)

  useEffect(() => {
    document.title = "焘韵文化";
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/home/dashboard"/>}/>
        <Route path="/test" element={<Test/>}/>
        <Route path="/error" element={<Error/>}/>
        <Route path="/login" element={<Login/>}/>

        {/* 后台 */}
        <Route
          path="/home"
          element={
            session.isLogin ? (
              <Suspense>
                <Home/>
              </Suspense>
            ) : (
              <Navigate to="/login"/>
            )
          }
        >
          <Route path="/home/dashboard" element={<Dashboard/>}/>
          <Route path="/home/userModule" element={<UserModule/>}/>
          <Route path="/home/company" element={<Company/>}/>
          <Route path="/home/companyExaminer" element={<CompanyExaminer/>}/>
          <Route path="/home/examApply" element={<ExamApply/>}/>
          <Route path="/home/examPlan" element={<ExamPlan/>}/>
          <Route path="/home/applyScore" element={<ApplyScore/>}/>
          <Route path="/home/valuationPlan" element={<ValuationPlan/>}/>
          <Route path="/home/valuationApply" element={<ValuationApply/>}/>
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRouter
