import request from "../util/request"
import global from "../global"
import {createModel} from "@rematch/core"
import {RootModel} from "./index"

/**
 * 会话
 */
export const session = createModel<RootModel>()({
  state: {
    isLogin: global.getSessionKey() !== "",
    info: {},
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 登录
     * @param payload
     * @param rootState
     * @returns {Promise<any>}
     */
    async login(payload, rootState): Promise<any> {
      let res = await request.post("/pub/v1/session/login", payload)
      if (res.code === 0) {
        dispatch.session.setData({isLogin: true})
        global.saveSessionKey(res.data.sessionKey, res.data.expireTime)//将登录信息保存到本地存储
      } else {
        console.error(res.msg);
      }
      return res
    },

    /**
     * 退出登录
     * @param payload
     * @param state
     * @returns {Promise<any>}
     */
    async logout(payload, state): Promise<any> {
      let res = await request.post("/pub/v1/session/logout", {})
      if (res.code === 0) {
        localStorage.clear()
        dispatch.session.setData({isLogin: false})
      }
      return res
    },

    /**
     * 读取用户信息
     *
     * @param payload
     * @param rootState
     */
    async getInfo(payload, rootState) {
      let res = await request.get("/pub/v1/session/info")
      if (res.code === 0) {
        dispatch.session.setData({info: res.data})
      }
      if (res.code !== 0) {
        console.log(res.msg);
      }
      return res
    },

    /**
     * 获取头像
     *
     * @param payload
     * @param rootState
     */
    async getAvatar(payload, rootState) {
      let res = await request.get("/pub/v1/session/avatar")
      if (res.code !== 0) {
        console.log(res.msg);
      }
      return res;
    },

    /**
     * 修改
     *
     * @param payload
     * @param rootState
     */
    async modify(payload, rootState) {
      let res = await request.put("/pub/v1/session/user", payload)
      if (res.code !== 0) {
        console.log(res.msg);
      }
      return res
    },
  }),
})
