import {Button, DatePicker, Form, Layout, message, Modal, Select, Space, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import React, {useEffect, useState} from "react";
import Search from "antd/es/input/Search";
import {useDispatch, useSelector} from "react-redux";
import "./ExamApply.less";
import ExamApplyModify from "./ExamApplyModify";
import ExamApplyNew from "./ExamApplyNew";
import ExamApplyInfo from "./ExamApplyInfo";
import dayjs from "dayjs";

interface DataType {
  key: string
  id: number
  orgName: string
  auditDate: string
  specialityName: string
  level: string
  teacher: string
  teacherMobile: string
  parents: string
  mobile: string
  companyName: string
  country: string
  nation: string
  idCardType: string
  idCardName: string
  idCardNumber: string
  namePinyin: string
  sex: string
  birthday: string
  area: string
  address: string
  photo: string
  accessKey: string
  applyStatusName: string
  sourceTypeName: string
  memo: string
  modified: string
  created: string
}

const ExamApply = () => {
  const examApply = useSelector((state: any) => state.examApply)
  const dispatch = useDispatch()

  const [newForm] = Form.useForm()
  const [modifyForm] = Form.useForm()

  const [messageApi, contextMessageHolder] = message.useMessage()

  const onSearch = (value: string) => {
    console.log('onSearch.....')
    let request = examApply.request
    request.keyword = value
    request.pageNum = 1
    request.specialityId = specialityId
    request.companyId = companyId
    request.auditDate = auditDate ? dayjs(auditDate).format("YYYY-MM-DD") : null;
    dispatch.examApply.setData({request})
  }

  const handleNew = () => {
    dispatch.examApply.setData({openNew: true})
  }

  const handleModify = (record: any) => {
    let info = Object.assign({}, record);//复制对象防止数据污染
    dispatch.examApply.setData({openModify: true, modifyPhoto: record.photoFile})

    //整理数据
    info.examPlanInfo = info.companyName + "/" + info.specialityName + "/" + info.auditDate
    info.birthday = dayjs(info.birthday)//生日格式化

    //查询专业等级列表
    dispatch.examPlan.info({id: info.planId}).then((res: any) => {
      dispatch.examApply.setData({modifyGrades: res.data.grades})
    })

    //传递数据
    modifyForm.setFieldsValue(info)
  }

  const [deleteId, setDeleteId] = useState<Number>()
  const handleDelete = (record: any) => {
    setDeleteId(record.id)
    setDeleteOpen(true)
  }

  const handleInfo = (record: any) => {
    dispatch.examApply.setData({openInfo: true, info: record})
  }

  //列
  const columns: ColumnsType<DataType> = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "姓名",
      dataIndex: "idCardName",
      key: "idCardName",
    },
    {
      title: "身份证号",
      dataIndex: "idCardNumber",
      key: "idCardNumber",
    },
    {
      title: "专业",
      dataIndex: "specialityName",
      key: "specialityName",
    },
    {
      title: "等级",
      dataIndex: "level",
      key: "level",
      render: (text) => text + "级",
    },
    {
      title: "电话",
      dataIndex: "mobile",
      key: "mobile",
    },
    // {
    //   title: "机构名称",
    //   dataIndex: "orgName",
    //   key: "orgName",
    // },
    // {
    //   title: "老师名称",
    //   dataIndex: "teacher",
    //   key: "teacher",
    // },
    // {
    //   title: "老师电话",
    //   dataIndex: "teacherMobile",
    //   key: "teacherMobile",
    // },
    {
      title: "报名状态",
      dataIndex: "applyStatusName",
      key: "applyStatusName",
      render: (text) => (text === '已通过' ? <Tag color="green">{text}</Tag> : <Tag color="red">{text}</Tag>),
    },
    {
      title: "报名来源",
      dataIndex: "sourceTypeName",
      key: "sourceTypeName",
    },
    {
      title: "报名时间",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => <OptionBtn record={record}/>,
      width: 180
    },
  ]

  const OptionBtn = (props: any) => {
    return (
      <>
        <Button type="link" size="small" onClick={() => handleInfo(props.record)}>
          查看
        </Button>
        {
          props.record.applyStatusName !== '已制证' &&
          <Button type="link" size="small" onClick={() => handleModify(props.record)}>
            修改
          </Button>
        }
        {
          props.record.applyStatusName === '申请中' &&
          <Button type="link" size="small" onClick={() => handleDelete(props.record)}>
            删除
          </Button>
        }
      </>
    )
  }

  //读取基础数据
  useEffect(() => {
    dispatch.examPlan.list()
    dispatch.country.list()
    dispatch.nation.list()
    dispatch.city.list()
  }, [dispatch.examPlan, dispatch.city, dispatch.country, dispatch.nation])

  //读取列表
  useEffect(() => {
    dispatch.examApply.page()
  }, [dispatch.examApply, examApply.request.keyword, examApply.request.pageNum, examApply.request.specialityId, examApply.request.auditDate, examApply.request.companyId])

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleDeleteCancel = () => {
    setDeleteOpen(false)
  }

  const handleDeleteOk = () => {
    setConfirmLoading(true)
    dispatch.examApply.remove({id: deleteId}).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("删除成功")
        dispatch.examApply.page()
        setDeleteOpen(false)
      } else {
        messageApi.error(res.msg)
      }
      setConfirmLoading(false)
    })
  }

  const handleDownload = () => {
    dispatch.examApply.download();
  }

  // 选择专业
  const [specialityId, setSpecialityId] = React.useState<string>('')
  const [specialities, setSpecialities] = React.useState<any[]>([])
  useEffect(() => {
    dispatch.speciality.list({auditType: 'EXAM'}).then((res: any) => {
      let specialities = res.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setSpecialities(specialities)
    })
  }, [dispatch.speciality])

  //选择机构
  const [companyId, setCompanyId] = React.useState<string>('')
  const [companies, setCompanies] = React.useState<any[]>([])
  useEffect(() => {
    dispatch.company.auditCompanies().then((res: any) => {
      let schools = res.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setCompanies(schools)
    })
  }, [dispatch.company])

  // 评审日期
  const [auditDate, setAuditDate] = React.useState<any>(null)

  return (
    <div className={'ExamApply'}>
      {contextMessageHolder}
      <Modal
        title="特别提醒"
        open={deleteOpen}
        onOk={handleDeleteOk}
        confirmLoading={confirmLoading}
        onCancel={handleDeleteCancel}
        okText="确认"
        cancelText="取消"
      >
        <p>删除数据无法恢复</p>
      </Modal>
      <Layout>
        <Space>
          <Select style={{width: 150}} onChange={(text: string) => setSpecialityId(text)} options={specialities}
                  placeholder="专业"/>
          {/*机构列表，显示自己和下属机构列表*/}
          <Select style={{width: 150}} onChange={(text: string) => setCompanyId(text)} options={companies} placeholder="机构"/>
          <DatePicker format="YYYY-MM-DD" onChange={(text) => setAuditDate(text)} placeholder="测评日期"/>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
          <Button type="primary" onClick={handleDownload} style={{backgroundColor: "orange"}}>
            下载
          </Button>
          <Button type="primary" onClick={handleNew} style={{backgroundColor: "#339933"}}>
            新增
          </Button>
          总人数:{examApply.data.total}人
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={examApply.data.list}
            pagination={{
              pageSize: examApply.data.pageSize, // 每页显示的条数
              total: examApply.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...examApply.request, pageNum: page, pageSize}
                dispatch.examApply.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <ExamApplyNew form={newForm} messageApi={messageApi}/>
      <ExamApplyModify form={modifyForm} messageApi={messageApi}/>
      <ExamApplyInfo/>
    </div>
  )
}

export default ExamApply