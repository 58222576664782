import {Button, DatePicker, Drawer, Form, Input, message, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";

/**
 * 修改考试计划
 *
 * @constructor
 */
const ExamPlanModify = () => {
  const [messageApi, contextMessageHolder] = message.useMessage()
  const examPlan = useSelector((state: any) => state.examPlan)
  const [auditCompanies, setAuditCompanies] = useState<any>([])
  const dispatch = useDispatch()
  const [modifyForm] = Form.useForm()
  const onClose = () => {
    dispatch.examPlan.setData({openModify: false})
  }
  const onSave = () => {
    modifyForm.submit()
  }
  const onFinish = (values: any) => {
    values.id = examPlan.modify.id
    values.auditDate = dayjs(values.auditDate).format("YYYY-MM-DD")
    values.expireDate = dayjs(values.expireDate).format("YYYY-MM-DD")
    dispatch.examPlan.modify(values).then((res:any) => {
      if (res.code !== 0) {
        messageApi.error(res.msg)
        return;
      }
      messageApi.success("修改成功")
      dispatch.examPlan.setData({openModify: false})
      dispatch.examPlan.page()
    });
  }

  // 专业列表
  const [specialityList, setSpecialityList] = useState([]);

  useEffect(() => {
    if (examPlan.openModify) {
      dispatch.company.speciality({auditType: 'EXAM'}).then((res: any) => {
        if (res.code > 0) {
          messageApi.error(res.msg)
          return;
        }
        setSpecialityList(res.data);
      });
      dispatch.company.auditCompanies().then((res: any) => {
        setAuditCompanies(res.data)
      })
    }
  }, [dispatch.company, dispatch.speciality, examPlan.openModify, messageApi]);

  return (<>
    {contextMessageHolder}
    <Drawer
      title="修改考试计划"
      width={500}
      onClose={onClose}
      open={examPlan.openModify}
      bodyStyle={{paddingBottom: 80}}
      extra={
        <Space>
          <Button onClick={onClose}>取消</Button>
          <Button onClick={onSave} type="primary">
            保存
          </Button>
        </Space>
      }
    >
      <Form form={modifyForm} onFinish={onFinish} layout="vertical" initialValues={examPlan.modify}>

        {/*选择专业*/}
        <Form.Item name="specialityId" label="专业" rules={[{required: true, message: "选择专业"}]}>
          <Select placeholder="选择专业">
            {
              specialityList.map((item: any, index: number) => {
                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
              })
            }
          </Select>
        </Form.Item>
        {/*选择考试日期*/}
        <Form.Item name="auditDate" label="考试日期" rules={[{required: true, message: "选择考试日期"}]}>
          <DatePicker placeholder="选择考试日期" format="YYYY-MM-DD"/>
        </Form.Item>
        {/*选择报名截止日期*/}
        <Form.Item name="expireDate" label="报名截止日期" rules={[{required: true, message: "选择报名截止日期"}]}>
          <DatePicker placeholder="选择报名截止日期" format="YYYY-MM-DD"/>
        </Form.Item>
        {/*输入考试地区*/}
        <Form.Item name="area" label="考试地区" rules={[{required: true, message: "选择考试地区"}]}>
          <Input placeholder="选择考试地区"/>
        </Form.Item>
        <Form.Item name="auditCompanyId" label="考试单位" rules={[{required: true, message: "选择考点"}]}>
          <Select placeholder="选择考点" onSelect={(e) => {
            let company = auditCompanies.find((obj: { id: number; }) => obj.id === e);
            modifyForm.setFieldValue('address', company.address);
          }}>
            {
              auditCompanies.map((item: any, index: number) => {
                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
              })
            }
          </Select>
        </Form.Item>
        {/*输入考试地址*/}
        <Form.Item name="address" label="考试地址" rules={[{required: true, message: "选择考试地址"}]}>
          <Input placeholder="选择考试地址"/>
        </Form.Item>
        {/*输入备注*/}
        <Form.Item
          name="memo"
          label="备注"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="输入备注"/>
        </Form.Item>
      </Form>
    </Drawer>
  </>);
}

export default ExamPlanModify;