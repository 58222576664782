import {Descriptions, Drawer, QRCode} from "antd";
import {useDispatch, useSelector} from "react-redux";

const ValuationPlanInfo = () => {
  const valuationPlan = useSelector((state: any) => state.valuationPlan)
  const dispatch = useDispatch()

  return (
    <Drawer
      title="查看计划详情"
      width={500}
      open={valuationPlan.openInfo}
      onClose={() => dispatch.valuationPlan.setData({openInfo: false})}
      bodyStyle={{paddingBottom: 80}}
    >
      <Descriptions title="" column={1}>
        <Descriptions.Item label="编号">{valuationPlan.info?.id}</Descriptions.Item>
        <Descriptions.Item label="评审专业">{valuationPlan.info?.specialityName}</Descriptions.Item>
        <Descriptions.Item label="评审日期">{valuationPlan.info?.auditDate}</Descriptions.Item>
        <Descriptions.Item label="报名截止日期">{valuationPlan.info?.expireDate}</Descriptions.Item>
        <Descriptions.Item label="地区">{valuationPlan.info?.area}</Descriptions.Item>
        <Descriptions.Item label="地址">{valuationPlan.info?.address}</Descriptions.Item>
        <Descriptions.Item label="备注">{valuationPlan.info?.memo}</Descriptions.Item>
        <Descriptions.Item label="修改时间">{valuationPlan.info?.modified}</Descriptions.Item>
        <Descriptions.Item label="创建时间">{valuationPlan.info?.created}</Descriptions.Item>
        <Descriptions.Item label="报名二维码">
          <QRCode value={'https://wemp.taoyun.art/newValuationApply?planId=' + valuationPlan.info?.id} />
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  )
}

export default ValuationPlanInfo