import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const speciality = createModel<RootModel>()({
  state: {
    list: [],
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 专业列表
     *
     * @param payload
     * @param rootState
     */
    async list(payload, rootState) {
      let res = await request.get("/pub/v1/speciality/list/" + payload.auditType)
      if (res.code > 0) {
        console.log(res.msg)
      } else {
        res.data.forEach((item: any) => {
          item.key = item.id
        })
        dispatch.speciality.setData({
          list: res.data,
        })
      }
      return res
    }
  }),
})