import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const city = createModel<RootModel>()({
  state: {
    list: [],
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async list(payload, rootState) {
      let res = await request.get("/pub/v1/city/list")
      if (res.code > 0) {
        console.log(res.msg)
      } else {
        dispatch.city.setData({
          list: res.data,
        })
      }
    },
  }),
})