import React, {useEffect} from "react"
import "./ExamPlan.less"
import {Button, DatePicker, Layout, message, Select, Space, Table, Tag} from "antd"
import {ColumnsType} from "antd/es/table";
import Search from "antd/es/input/Search";
import {useDispatch, useSelector} from "react-redux";
import ExamPlanInfo from "./ExamPlanInfo";
import ExamPlanAdd from "./ExamPlanAdd";
import ExamPlanModify from "./ExamPlanModify";
import dayjs from "dayjs";
import ExamPlanPoster from "./ExamPlanPoster";

/**
 * ExamPlan
 * @constructor
 */

interface DataType {
  key: string
  id: number
  companyName: string
  specialityId: number
  specialityName: string
  auditDate: string//考试日期
  expireDate: number//报名截止日期
  area: string  //考试区域
  address: string//考试地址
  memo: string
  modified: string
  created: string
  canRemove: boolean
  canModify: boolean
}

const ExamPlan: React.FC = () => {
  const examPlan = useSelector((state: any) => state.examPlan)
  const dispatch = useDispatch()
  const [messageApi, contextMessageHolder] = message.useMessage()
  const columns: ColumnsType<DataType> = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "专业",
      dataIndex: "specialityName",
      key: "specialityName",
    },
    {
      title: "考试日期",
      dataIndex: "auditDate",
      key: "auditDate",
    },
    {
      title: "报名截止日期",
      dataIndex: "expireDate",
      key: "expireDate",
    },
    {
      title: "考点单位",
      dataIndex: "companyName",
      key: "companyName",
      render: (text: any, record: any) => {
        return <Tag color="blue">{text}</Tag>
      }
    },
    {
      title: "考试地址",
      dataIndex: "address",
      key: "address",
    },
    // {
    //   title: "备注",
    //   dataIndex: "memo",
    //   key: "memo",
    // },
    // {
    //   title: "修改时间",
    //   dataIndex: "modified",
    //   key: "modified",
    // },
    {
      title: "创建时间",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => <OptionBtn record={record}/>
    },
  ]

  //读取列表
  useEffect(() => {
    dispatch.examPlan.page()
  }, [dispatch.examPlan, examPlan.request.keyword, examPlan.request.pageNum, examPlan.request.pageSize,
    examPlan.request.specialityId, examPlan.request.auditDate])

  //搜索
  const onSearch = (value: string) => {
    let request = examPlan.request
    request.keyword = value
    request.pageNum = 1
    request.specialityId = specialityId
    request.auditDate = auditDate?dayjs(auditDate).format("YYYY-MM-DD"):null;
    dispatch.examPlan.setData({request})
  }

  const handleModify = (record: any) => {
    let info = {...record}
    info.auditDate = dayjs(info.auditDate)
    info.expireDate = dayjs(info.expireDate)
    dispatch.examPlan.setData({openModify: true, modify: info})
  }

  const handleInfo = (record: any) => {
    dispatch.examPlan.setData({openInfo: true, info: record})
  }

  const handlePoster = (record: any) => {
    dispatch.examPlan.setData({openPoster: true, info: record})
  }

  function handleRemove(record: any) {
    dispatch.examPlan.remove({id: record.id}).then(() => {
      messageApi.success("删除成功");
      dispatch.examPlan.page();
    });
  }

  const OptionBtn = (props: any) => {
    return (
      <>
        <Button type="link" size="small" onClick={() => handleInfo(props.record)}>
          查看
        </Button>
        {
          props.record.canModify && (
            <Button type="link" size="small" onClick={() => handleModify(props.record)}>
              修改
            </Button>
          )
        }
        {
          props.record.canRemove && (
            <Button type="link" size="small" onClick={() => handleRemove(props.record)}>
              删除
            </Button>
          )
        }
        <Button type="link" size="small" onClick={() => handlePoster(props.record)}>
          报名海报
        </Button>
      </>
    )
  }

  const handleNew = () => {
    dispatch.examPlan.setData({openAdd: true})
  }

  // 选择专业
  const [specialityId, setSpecialityId] = React.useState<string>('')
  const [specialities, setSpecialities] = React.useState<any[]>([])
  useEffect(() => {
    dispatch.speciality.list({auditType: 'EXAM'}).then((res: any) => {
      let specialities = res.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setSpecialities(specialities)
    })
  }, [dispatch.speciality])

  // 评审日期
  const [auditDate, setAuditDate] = React.useState<any>(null)

  return (
    <div className={'ExamPlan'}>
      {contextMessageHolder}
      <Layout>
        <Space>
          <Select style={{width: 150}} onChange={(text: string) => setSpecialityId(text)} options={specialities} placeholder="专业"/>
          <DatePicker format="YYYY-MM-DD" onChange={(text) => setAuditDate(text)} placeholder="测评日期" />
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
          <Button type="primary" onClick={handleNew} style={{backgroundColor: "#339933"}}>
            新增
          </Button>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={examPlan.data.list}
            pagination={{
              pageSize: examPlan.data.pageSize, // 每页显示的条数
              total: examPlan.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...examPlan.request, pageNum: page, pageSize}
                dispatch.examPlan.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <ExamPlanInfo/>
      <ExamPlanAdd/>
      <ExamPlanModify/>
      <ExamPlanPoster/>
    </div>
  )
}

export default ExamPlan
