import React, {useEffect, useState} from "react"
import {
  Button,
  Descriptions,
  Drawer,
  Input,
  Layout,
  Select,
  Space,
  Table,
  Tag
} from "antd"
import type {ColumnsType} from "antd/es/table"
import {useDispatch, useSelector} from "react-redux"
import "../../global";

const {Search} = Input

//定义companyType
/**
 * 定义companyType
 * @constructor
 */
interface CompanyType {
  id: number
  companyType: string
  companyTypeName: string
  parent: string
  name: string
  address: string
  merchantName: string
  regNum: string
  licenceCopyKey: string
  legalPerson: string
  idCardCopyKey: string
  idCardNationalKey: string
  idCardName: string
  idCardNumber: string
  idCardAddress: string
  cardPeriodBegin: string
  cardPeriodEnd: string
  linkman: string
  mobile: string
  province: string
  city: string
  enabled: boolean
  expireDate: string
  memo: string
  modified: string
  created: string
}

//单位类型
const companyTypes = [
  {value: 'REGION', label: '区级指导单位'},
  {value: 'TEST_CENTRE', label: '考试中心'},
]

const CompanyTable: React.FC = () => {
  const [infoOpen, setInfoOpen] = useState(false)
  const [info, setInfo] = useState<CompanyType>({} as CompanyType)

  const company = useSelector((state: any) => state.company)
  const dispatch = useDispatch()

  const columns: ColumnsType<CompanyType> = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "状态",
      dataIndex: "enabled",
      key: "enabled",
      render: (text: any, record: any) => {
        return text ? <Tag color="green">正常</Tag> : <Tag color="red">无效</Tag>
      }
    },
    {
      title: "单位类型",
      dataIndex: "companyTypeName",
      key: "companyTypeName",
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "联系人",
      dataIndex: "linkman",
      key: "linkman",
    },
    {
      title: "手机号码",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "备注",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "创建时间",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => {

        return (
          <>
            <Button type="link" size={"small"} onClick={() => handleInfo(record)}>
              查看
            </Button>
          </>
        )
      },
    },
  ]

  const onSearch = (value: string) => {
    let request = company.request
    request.keyword = value
    request.pageNum = 1
    request.companyType = companyType
    dispatch.company.setData({request})
    dispatch.company.page()
  }

  const handleInfo = (record: any) => {
    setInfo({...record});
    setInfoOpen(true)
  }

  useEffect(() => {
    dispatch.company.page()
  }, [company.request.keyword, company.request.pageNum, dispatch.company])

  const [companyType, setCompanyType] = useState('')

  // @ts-ignore
  return (
    <>
      <Layout>
        <Space>
          <Select onChange={(text: string) => setCompanyType(text)} options={companyTypes} placeholder="选择单位类型"/>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={company.data.list}
            pagination={{
              pageSize: company.data.pageSize, // 每页显示的条数
              total: company.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...company.request, pageNum: page, pageSize}
                dispatch.company.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>

      <Drawer
        title="查看合作单位"
        width={500}
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        bodyStyle={{paddingBottom: 80}}
      >
        <Descriptions title="" column={1}>
          <Descriptions.Item label="编号">{info?.id}</Descriptions.Item>
          <Descriptions.Item label="上级单位">{info?.parent}</Descriptions.Item>
          <Descriptions.Item label="单位类型">{info?.companyTypeName}</Descriptions.Item>
          <Descriptions.Item label="名称">{info?.name}</Descriptions.Item>
          <Descriptions.Item label="地址">{info?.address}</Descriptions.Item>
          <Descriptions.Item label="有效状态">{info?.enabled ? '正常' : '无效'}</Descriptions.Item>
          <Descriptions.Item label="到期日期">{info?.expireDate}</Descriptions.Item>
          <Descriptions.Item label="单位名称">{info?.merchantName}</Descriptions.Item>
          <Descriptions.Item label="执照编号">{info?.regNum}</Descriptions.Item>
          <Descriptions.Item label="联系人">{info?.linkman}</Descriptions.Item>
          <Descriptions.Item label="手机号码">{info?.mobile}</Descriptions.Item>
          <Descriptions.Item label="备注">{info?.memo}</Descriptions.Item>
          <Descriptions.Item label="新增时间">{info?.created}</Descriptions.Item>
        </Descriptions>
      </Drawer>
    </>
  )
}

export default CompanyTable

