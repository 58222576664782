import React from 'react';
import './CompanyExaminer.less';
import CompanyExaminerTable from "./CompanyExaminerTable";

const CompanyExaminer: React.FC = () => {

  return (
    <div className='CompanyExaminer'>
      <CompanyExaminerTable/>
    </div>
  )
}

export default CompanyExaminer;