import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const menu = createModel<RootModel>()({
  state: {
    list: [],
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setState(state, payload) {
      return {...state, ...payload};
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async getList(payload, rootState) {
      let res = await request.get("/pub/v1/menu/list");
      if (res.code > 0) {
        console.log(res.msg);
      } else {
        dispatch.menu.setState({
          list: res.data.map((item: any) => {return item.id}),
        });
      }
      return res;
    },
  }),
})