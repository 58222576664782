import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";
import global from "../global";

export const file = createModel<RootModel>()({
  state: {
    fileType: 'PRIVATE', //文件类型
    upload: {
      //上传地址
      url: global.getApiServer() + "/pub/v1/file/upload",
    }
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 生成上传文件key，传入文件夹名称
     * @param payload
     * @param rootState
     */
    async genKey(payload, rootState) {
      let res = await request.post("/pub/v1/file/genKey", payload);
      if (res.code > 0) {
        console.log(res.msg)
      }
      return res;
    },

    /**
     * 上传文件
     * @param payload
     * @param rootState
     */
    async upload(payload, rootState) {
      let {fileType, fileKey} = payload;
      let res = await request.upload("/pub/v1/file/upload", payload, fileType, fileKey);
      if (res.code > 0) {
        console.log(res.msg)
      }
      let url = global.getApiServer() + "/pub/v1/file/open?fileKey=" + res.data.fileKey + "&accessKey=" + res.data.accessKey;
      return {url, key: res.data.fileKey, thumbnailUrl: url, extra: res.data.contentType};
    }
  }),
})