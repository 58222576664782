import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const valuationPlan: any = createModel<RootModel>()({
  state: {
    list: [],

    openPoster: false,//打开海报界面
    openAdd: false,
    openInfo: false,//打开详情界面
    info: {},//详情数据

    openModify: false,//打开修改界面
    modify: {},//修改的数据

    request: {
      pageNum: 1,
      pageSize: 20,
      keyword: "",
      specialityId: "",
      auditDate: null,
    },
    data: {
      pageSize: 0,
      total: 0,
      list: [],
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async list(payload, rootState) {
      let res = await request.get("/partner/v1/valuation/plan/list");
      if (res.code !== 0) {
        console.error(res.msg);
      } else {
        dispatch.valuationPlan.setData({list: res.data});
      }
      return res;
    },

    /**
     * 查询
     *
     * @param payload
     * @param rootState
     */
    async info(payload, rootState) {
      let res = await request.get("/partner/v1/valuation/plan/info/" + payload.id);
      if (res.code !== 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 新增
     * @param payload
     * @param rootState
     */
    async add(payload, rootState) {
      let res = await request.post("/partner/v1/valuation/plan", payload);
      if (res.code !== 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 修改
     *
     * @param payload
     * @param rootState
     */
    async modify(payload, rootState) {
      let res = await request.put("/partner/v1/valuation/plan", payload);
      if (res.code !== 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 删除
     *
     * @param payload
     * @param rootState
     */
    async remove(payload, rootState) {
      let res = await request.delete("/partner/v1/valuation/plan/" + payload.id);
      if (res.code !== 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 分页查询
     *
     * @param payload
     * @param rootState
     */
    async page(payload, rootState) {
      let res = await request.post("/partner/v1/valuation/plan/page", rootState.valuationPlan.request);
      if (res.code !== 0) {
        console.error(res.msg);
      } else {
        res.data.list.forEach((item: any) => {
          item.key = item.id
        })
        dispatch.valuationPlan.setData({data: res.data})
      }
      return res;
    }
  }),
})