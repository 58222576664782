import React from 'react';
import './UserModule.less';
import UserModuleTable from "./UserModuleTable";

const UserModule: React.FC = () => {

  return (
    <div className='UserModule'>
      <UserModuleTable/>
    </div>
  )
}

export default UserModule;