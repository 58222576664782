import { createModel } from "@rematch/core"
import { RootModel } from "./index"
import request from "../util/request"

/**
 * 仪表盘
 */
export const dashboard = createModel<RootModel>()({
  state: {
    clientGlobal: {
      hospital: 0,
      hospitalOnline: 0,
      pharmacy: 0,
      pharmacyOnline: 0,
      total: 0,
      online: 0,
    },
    bindGlobal: {
      hospital: 0,
      pharmacy: 0,
    },
    unbindGlobal: {
      hospital: 0,
      pharmacy: 0,
    },
    newestVersion: {
      days: 0,
      percent: 0,
    },

    //月活跃
    installMonth: [],
    onlineMonth: [],
    midTrend: [],

    //机构安装排名
    installPList: [], //药店
    installHList: [], //医院
    midList: [],

    //排名
    verGlobal: [],
    osGlobal: [],
    systems: [],

    //地区数据
    areaTotal: [],
    areaOnline: [],
    areas: [],
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setData(state, payload) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async updateDate(payload, state) {
      dispatch.dashboard.client(payload)
      dispatch.dashboard.bind(payload)
      dispatch.dashboard.unbind(payload)
      dispatch.dashboard.newestVer(payload)
      dispatch.dashboard.ver(payload)
      dispatch.dashboard.os(payload)
      dispatch.dashboard.area({ online: 2 }) //全部
      dispatch.dashboard.area({ online: 1 })
      dispatch.dashboard.installOrg({ type: "P", top: 10 })
      dispatch.dashboard.installOrg({ type: "H", top: 10 })
      dispatch.dashboard.monthInstall(payload)
      dispatch.dashboard.monthOnline(payload)
    },
    /**
     * 客户端信息
     * @param payload
     * @param state
     */
    async client(payload, state) {
      const res = await request.get("/v1/dashboard/client")
      dispatch.dashboard.setData({ clientGlobal: res.data })
    },

    /**
     * 绑定信息
     * @param payload
     * @param state
     */
    async bind(payload, state) {
      const res = await request.get("/v1/dashboard/bind/null")
      dispatch.dashboard.setData({ bindGlobal: res.data })
    },

    /**
     * 解绑信息
     * @param payload
     * @param state
     * @returns {Promise<any>}
     */
    async unbind(payload, state) {
      const res = await request.get("/v1/dashboard/unbind/null")
      dispatch.dashboard.setData({ unbindGlobal: res.data })
    },

    /**
     * 最新版本信息
     * @param payload
     * @param state
     */
    async newestVer(payload, state) {
      const res = await request.get("/v1/dashboard/newestVer")
      dispatch.dashboard.setData({ newestVersion: res.data })
    },

    /**
     * 版本信息
     * @param payload
     * @param state
     * @returns {Promise<any>}
     */
    async ver(payload, state) {
      const res = await request.get("/v1/dashboard/ver/10")
      dispatch.dashboard.setData({ verGlobal: res.data })
    },

    /**
     * os信息
     * @param payload
     * @param state
     * @returns {Promise<any>}
     */
    async os(payload, state) {
      const res = await request.get("/v1/dashboard/os/10")
      if (state.dashboard.systems.length === 0) {
        dispatch.dashboard.setData({ systems: res.data })
      }
      dispatch.dashboard.setData({ osGlobal: res.data })
    },

    /**
     * 地区使用情况
     * @param payload
     * @param state
     * @returns {Promise<any>}
     */
    async area(payload, state) {
      const res = await request.get("/v1/dashboard/area/" + payload.online)
      if (payload.online === 1 && state.dashboard.areas.length === 0) {
        dispatch.dashboard.setData({ areas: res.data })
      }
      dispatch.dashboard.setData({ ["area" + (payload.online === 1 ? "Online" : "Total")]: res.data })
    },

    /**
     * 安装机构情况
     * @param payload
     * @param state
     * @returns {Promise<any>}
     */
    async installOrg(payload, state) {
      const res = await request.get("/v1/dashboard/installOrg/" + payload.type + "/" + payload.top)
      if (payload.type === "P" && state.dashboard.midList.length === 0) {
        dispatch.dashboard.setData({ midList: res.data })
      }
      dispatch.dashboard.setData({ ["install" + payload.type + "List"]: res.data })
    },

    /**
     * 安装月份趋势
     * @param payload
     * @param state
     * @returns {Promise<any>}
     */
    async monthInstall(payload, state) {
      const res = await request.get("/v1/dashboard/month/install")
      dispatch.dashboard.setData({ installMonth: res.data })
    },

    /**
     * 月活跃趋势
     * @param payload
     * @param state
     * @returns {Promise<any>}
     */
    async monthOnline(payload, state) {
      const res = await request.get("/v1/dashboard/month/online")
      if (state.dashboard.midTrend.length === 0) {
        dispatch.dashboard.setData({ midTrend: res.data })
      }
      dispatch.dashboard.setData({ onlineMonth: res.data })
    },
  }),
})
