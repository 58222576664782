import {Button, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {fabric} from 'fabric';
import {useEffect, useRef} from "react";
import global from "../../global";
import {StaticCanvas} from "fabric/fabric-impl";

const ExamPlanPoster = () => {
  const examPlan = useSelector((state: any) => state.examPlan)
  const dispatch = useDispatch()
  const canvasRef = useRef(null);// 静态画布对象

  const handleCancel = () => {
    dispatch.examPlan.setData({openPoster: false})
  };

  /**
   * 设置背景图片
   * @param canvas
   * @param url {String} 图片地址
   */
  const setDefaultBackgroundImg = (canvas: StaticCanvas, url: string) => {
    fabric.Image.fromURL(
      url,
      (img) => {
        // 设置背景图
        canvas.setBackgroundImage(
          img,
          canvas.renderAll.bind(canvas),
          {
            // @ts-ignore
            scaleX: canvas.width / img.width,
            // @ts-ignore
            scaleY: canvas.height / img.height,
            name: 'bgImage'
          }
        )
      }
    )
  }

  /**
   * 画布中添加文字函数
   *
   * @param canvas
   * @param text
   * @param left
   * @param top
   * @param fontSize
   */
  const setText = (canvas: StaticCanvas, text: string, left: number, top: number, fontSize: number) => {
    const textObj = new fabric.Text(text, {
      left,
      top,
      fontSize,
      fontFamily: 'Arial',
      fill: '#ffffff',
      textAlign: 'left',
      fontWeight: 'normal',
      name: 'text'
    });
    canvas.add(textObj);
  }

  /**
   * 画布中添加二维码图片函数
   *
   * @param canvas
   * @param url {String} 图片的二维码地址
   * @param left {Number} 图片的left值
   * @param top {Number} 图片的top值
   * @param width {Number} 图片的宽度
   */
  const setQrCodeImg = (canvas: StaticCanvas, url: string, left: number, top: number, width: number) => {
    fabric.Image.fromURL(
      url,
      (img) => {
        // 设置背景图
        canvas.add(
          img.set({
            left,
            top,
            width,
            height: width,
            name: 'qrCodeImg'
          })
        )
      }
    )
  }

  /**
   * 计算文字的left
   *
   * @param text {String} 文字
   * @param fontSize  {number} 字体大小
   */
  const getLeft = (text: string, fontSize: number) => {
    return 200 - (text.length * fontSize / 2);
  }

  /**
   * 用来重新渲染canvas
   */
  useEffect(() => {
    if (examPlan.openPoster) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const canvas = new fabric.StaticCanvas(canvasRef.current, {
        width: 400,
        height: 554,
      });
      //设置背景图片
      setDefaultBackgroundImg(canvas, 'apply_poster.png');
      //添加二维码图片
      let str = 'https://wemp.taoyun.art/newExamApply?planId=' + examPlan.info.id;
      //对str进行URLEncode
      setQrCodeImg(canvas, global.getApiServer() + '/pub/v1/qrCode/gen?size=120&str=' + encodeURIComponent(str), 140, 250, 120)
      let name = "扫码报名";
      setText(canvas, name, getLeft(name, 8), 380, 8);
      //添加第二行文字：
      let text = examPlan.info.specialityName + "测评";
      setText(canvas, text , getLeft(text, 10), 400, 10);
      //添加第一行文字：新华教育
      name = examPlan.info.companyName;
      setText(canvas, name, getLeft(name, 12), 430, 12);
      //已知总宽度400，根据地址文字长度，要求居中文字，那么计算出left值
      let address = '考试地址：' + examPlan.info.address;
      setText(canvas, address, getLeft(address, 12) + 12, 460, 12);
      let auditDate = '测评日期：' + examPlan.info.auditDate;
      setText(canvas, auditDate, getLeft(auditDate, 12) + 30, 490, 12);
      let expireDate = '报名截止日期：' + examPlan.info.expireDate;
      setText(canvas, expireDate, getLeft(expireDate, 12) + 30, 510, 12);
    }
  }, [examPlan.info.address, examPlan.info.auditDate, examPlan.info.companyName, examPlan.info.expireDate, examPlan.info.id, examPlan.info.specialityName, examPlan.openPoster]);

  const onSave = () => {
    if (canvasRef.current) {
      let canvas:HTMLCanvasElement = canvasRef.current;
      const imageUrl = canvas.toDataURL('image/png');

      // Create a link element
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = examPlan.info.companyName + examPlan.info.auditDate + '.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <Modal
      width={448}
      open={examPlan.openPoster}
      title="报名海报"
      onCancel={handleCancel}
      afterClose={handleCancel}
      footer={
        <>
          <Button onClick={onSave}>保存图片</Button>
        </>
      }
    >
      <canvas ref={canvasRef} width="400" height="554"/>
    </Modal>
  )
}

export default ExamPlanPoster