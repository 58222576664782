import React from 'react';
import './Test.less';

const Test: React.FC = () => {

  return (
    <div className='Test'>
      测试页面
    </div>
  )
}

export default Test;