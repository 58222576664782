import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const valuationApply = createModel<RootModel>()({
  state: {
    openNew: false,
    openModify: false,
    openInfo: false,

    //修改要用到
    modifyPhoto: {},
    modifyGrades: [],

    info: {},//详情

    request: {
      pageNum: 1,
      pageSize: 20,
      keyword: "",
      specialityId: "",
      auditDate: null,
    },

    data: {
      list: [],
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 读取用户信息
     * @param payload
     * @param rootState
     */
    async getInfo(payload, rootState) {
      let res = await request.get("/partner/v1/valuation/apply/" + payload.id)
      if (res > 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 分页查询
     * @param payload
     * @param rootState
     */
    async page(payload, rootState) {
      const {data} = await request.post("/partner/v1/valuation/apply/page", rootState.valuationApply.request)
      data.list.forEach((item: any) => {
        item.key = item.id
      })

      dispatch.valuationApply.setData({data})
    },

    /**
     * 新增
     *
     * @param payload
     * @param rootState
     */
    async add(payload, rootState) {
      let res = await request.post("/partner/v1/valuation/apply", payload)
      if (res.code > 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 修改
     *
     * @param payload
     * @param rootState
     */
    async modify(payload, rootState) {
      let res = await request.put("/partner/v1/valuation/apply", payload)
      if (res.code > 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 删除
     *
     * @param payload
     * @param rootState
     */
    async remove(payload, rootState) {
      let res = await request.delete("/partner/v1/valuation/apply/" + payload.id)
      if (res.code > 0) {
        console.error(res.msg);
      }
      return res;
    },

    /**
     * 下载
     *
     * @param payload
     * @param rootState
     */
    async download(payload, rootState) {
      await request.download("/partner/v1/valuation/apply/download", {keyword: rootState.valuationApply.request.keyword})
    }

  }),
})