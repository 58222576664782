import {
  Button,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Space,
  Upload,
  UploadFile,
  UploadProps
} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {RcFile, UploadChangeParam} from "antd/es/upload";
import global from "../../global";
import dayjs from "dayjs";
import idCard from "../../util/idCard";

const {Option} = Select

const ValuationApplyNew = (props: { form: any, messageApi: any }) => {
  const examPlan = useSelector((state: any) => state.examPlan)
  const valuationApply = useSelector((state: any) => state.valuationApply)
  const file = useSelector((state: any) => state.file)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [grades, setGrades] = useState([])

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      let data = info.file.response.data;
      let imageUrl = global.getFileOpenUrl(data);
      setImageUrl(imageUrl);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const UploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>上传</div>
    </div>
  );

  const onNewClose = () => {
    dispatch.valuationApply.setData({openNew: false})
  }

  const onNewSave = () => {
    props.form.submit()
  }

  const onNewFinish = (values: any) => {
    values.birthday = dayjs(values.birthday).format("YYYY-MM-DD")
    dispatch.valuationApply.add(values).then((res: any) => {
      if (res.code === 0) {
        props.messageApi.success("创建成功")
        dispatch.valuationApply.page()
        dispatch.valuationApply.setData({openNew: false})
        // props.form.resetFields()
      } else {
        props.messageApi.error(res.msg)
      }
    });
  }

  const onPlanChange = (value: any) => {
    let plan = examPlan.list.filter((item: any) => item.id === value)
    setGrades(plan[0].grades)
  }

  const onIdCardNumberChange = (e: any) => {
    let {value} = e.target
    if (value.length === 18) {
      let birthday = dayjs(idCard.getBirthday(value));
      let sex = idCard.getSex(value);
      props.form.setFieldsValue({birthday, sex})
    }
  }

  return (
    <Drawer
      title="新增报名"
      width={500}
      onClose={onNewClose}
      open={valuationApply.openNew}
      bodyStyle={{paddingBottom: 80}}
      extra={
        <Space>
          <Button onClick={onNewClose}>取消</Button>
          <Button onClick={onNewSave} type="primary">
            保存
          </Button>
        </Space>
      }
    >
      <Form form={props.form} onFinish={onNewFinish} layout="vertical">
        <Form.Item name="planId" label="评审计划" rules={[{required: true, message: "选择评审计划"}]}>
          <Select placeholder="选择评审计划" onChange={onPlanChange}>
            {
              examPlan.list.map((item: any, index: number) => {
                return <Option key={index} value={item.id}>{item.companyName}/{item.specialityName}/{item.auditDate}</Option>
              })
            }
          </Select>
        </Form.Item>

        <Form.Item name="level" label="报名级别" rules={[{required: true, message: "选择报名级别"}]}>
          <Select placeholder="选择报名级别">
            {
              grades.map((item: any, index: number) => {
                return <Option key={index} value={item.level}>{item.name}</Option>
              })
            }
          </Select>
        </Form.Item>

        <Form.Item name="idCardName" label="姓名" rules={[{required: true, message: "姓名"}]}>
          <Input placeholder="姓名"/>
        </Form.Item>

        <Form.Item name="idCardNumber" label="身份证号码" rules={[{required: true, message: "输入身份证号码"}]}>
          <Input placeholder="输入身份证号码" onChange={onIdCardNumberChange}/>
        </Form.Item>

        <Form.Item name="mobile" label="联系电话" rules={[{required: true, message: "输入联系电话"}]}>
          <Input placeholder="联系电话"/>
        </Form.Item>

        <Form.Item
          name="photo"
          label="上传照片"
          valuePropName="file"
          getValueFromEvent={(e) => {
            if (e.file.status !== "done") return
            return e.file.response.data.fileKey
          }}
          rules={[{required: true, message: "选择文件"}]}
        >
          <Upload
            name="file"
            data={{folder: 'photo', fileType: 'PRIVATE'}}//为上传文件增加额外的body参数
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            headers={global.getUploadHeaders()} //指定上传头，这个有授权sessionKey
            action={file.upload.url + "/add"} //上传地址，不预先获取fileKey，每次都生成新的fileKey
            beforeUpload={beforeUpload}   //上传前的钩子函数，检查文件大小、类型等
            onChange={handleChange}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : UploadButton}
          </Upload>
        </Form.Item>

        <Form.Item name="orgName" label="所在机构">
          <Input placeholder="请输入机构名称"/>
        </Form.Item>

        <Form.Item
          name="memo"
          label="备注"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="输入应用备注"/>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default ValuationApplyNew;