import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import AppRouter from './AppRouter';
import reportWebVitals from './reportWebVitals';
import {store} from "./store";
import {Provider} from "react-redux";
import {ConfigProvider} from "antd";
import zhCN from 'antd/locale/zh_CN';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
        <Provider store={store}>
          <ConfigProvider locale={zhCN}>
            <AppRouter />
          </ConfigProvider>
        </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();