const idCard = {

  /**
   * 通过身份证判断性别
   *
   * @param idCard
   */
  getSex(idCard: string) {
    let sexStr = "";
    if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
      sexStr = "男";
    } else {
      sexStr = "女";
    }
    return sexStr;
  },

  /**
   * 通过身份证号码查出生日期
   * @param idCard
   */
  getBirthday(idCard: string) {
    let birthday = "";
    if (idCard != null && idCard !== "") {
      if (idCard.length === 15) {
        birthday = "19" + idCard.substring(6, 12);
      } else if (idCard.length === 18) {
        birthday = idCard.substring(6, 14);
      }

      birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    }

    return birthday;
  }
}

export default idCard